/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Comic Code Ligatures";
  src: url("../fonts/ComicCodeLigatures-Regular.woff") format("woff");
  font-display: swap;
}

html, body {
  @apply font-comic-code;

  @apply selection:bg-info/80;
}

[data-localize] {
  opacity: 0;
}

[data-localize="done"] {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.view-transition-content {
  view-transition-name: content;
}

@keyframes fade-in {
  from { opacity: 0; }
}

@keyframes fade-out {
  to { opacity: 0; }
}

@keyframes slide-from-right {
  from { transform: translateX(30px); }
}

@keyframes slide-to-left {
  to { transform: translateX(-30px); }
}

::view-transition-old(content) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(content) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

header {
  view-transition-name: header;
}

main {
  view-transition-name: main;
}

.view-transition-categories {
  view-transition-name: categories;
}

::view-transition-old(header)
::view-transition-new(header),
::view-transition-old(main),
::view-transition-new(main),
::view-transition-old(categories),
::view-transition-new(categories) {
  animation: none;
  display: block;
  mix-blend-mode: normal;
}

::view-transition-group(header),
::view-transition-group(main),
::view-transition-group(content) {
  animation-duration: 270ms;
}

::view-transition-group(header) {
  animation-timing-function: ease-in-out;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-base-100;
}

::-webkit-scrollbar-thumb {
  @apply rounded bg-neutral/60;
  @apply transition duration-200 ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  @apply transition duration-200 ease-in-out;
  @apply cursor-pointer bg-neutral/90;
}

/* If you need to add custom styles, do so down here */
