/*
  Syntax Highlighting for Code Snippets

  https://www.bridgetownrb.com/docs/liquid/tags#stylesheets-for-syntax-highlighting

  Other styles available eg. https://github.com/jwarby/jekyll-pygments-themes
  To use another style, delete all styles in this file and replace them with
  the new styles. Or create your own!

*/

code {
  font-family: 'Comic Code Ligatures';
  font-size: 0.5rem;
}

pre.highlight {
  font-family: 'Comic Code Ligatures';
  line-height: 1.4;

  @apply my-0;
}

div.highlight pre.highlight::before {
  content: unset;
  margin: unset;
}

html div.highlight {
  @apply selection:bg-[#515c7e40];
}

div.highlight {
  @apply mockup-code;
  background-color: #1a1b26;

  margin-top: 2em;
  margin-bottom: 2em;

  @apply max-w-[90vw] lg:max-w-none;
}

div.highlight ::-webkit-scrollbar {
  height: 6px;
}

div.highlight ::-webkit-scrollbar-thumb {
  background-color: #c0caf5;
}

div.highlight ::-webkit-scrollbar-track {
  background-color: #1a1b26;
}

.highlight pre { background-color: #1a1b26; }
.highlight .hll { background-color: #1a1b26; }
.highlight pre { color: #c0caf5; } /* Punctuation */
.highlight .p { color: #7aa2f7; } /* Error */
.highlight .err { color: #c0caf5; } /* Base Style */
.highlight .n { color: #c0caf5; } /* Name Attribute */
.highlight .na { color: #bb9af7; } /* Name Builtin */
.highlight .nb { color: #c0caf5; } /* Name Class */
.highlight .nc { color: #c0caf5; } /* Name Constant */
.highlight .no { color: #bb9af7; } /* Name Decorator */
.highlight .nd { color: #c0caf5; } /* Name Entity */
.highlight .ni { color: #c0caf5; } /* Name Exception */
.highlight .ne { color: #bb9af7; } /* Name Function */
.highlight .nf { color: #7aa2f7; } /* Name Label */
.highlight .nl { color: #c0caf5; } /* Name Namespace */
.highlight .nn { color: #c0caf5; } /* Name Other */
.highlight .nx { color: #c0caf5; } /* Name Property */
.highlight .py { color: #c0caf5; } /* Name Tag */
.highlight .nt { color: #de5971; } /* Name Variable */
.highlight .nv { color: #c0caf5; } /* Name Variable Class */
.highlight .vc { color: #c0caf5; } /* Name Variable Global */
.highlight .vg { color: #c0caf5; } /* Name Variable Instance */
.highlight .vi { color: #c0caf5; } /* Name Builtin Pseudo */
.highlight .bp { color: #c0caf5; } /* Base Style */
.highlight .g { color: transparent; } /*  */
.highlight .gd { color: transparent; } /* Base Style */
.highlight .o { color: #89ddff; } /* Operator Word */
.highlight .ow { color: #89ddff; } /* Base Style */
.highlight .c { color: #51597d; font-style: italic; } /* Comment Multiline */
.highlight .cm { color: #51597d; font-style: italic; } /* Comment Preproc */
.highlight .cp { color: #51597d; font-style: italic; } /* Comment Single */
.highlight .c1 { color: #51597d; font-style: italic; } /* Comment Special */
.highlight .cs {
  color: #51597d;
  background-color: #bb9af7;
  font-style: italic;
}
/* Base Style */
.highlight .k { color: #bb9af7; } /* Keyword Constant */
.highlight .kc { color: #bb9af7; } /* Keyword Declaration */
.highlight .kd { color: #bb9af7; } /* Keyword Namespace */
.highlight .kn { color: #bb9af7; } /* Keyword Pseudo */
.highlight .kp { color: #bb9af7; } /* Keyword Reserved */
.highlight .kr { color: #bb9af7; } /* Keyword Type */
.highlight .kt { color: transparent; } /* Base Style */
.highlight .l { color: #ff9e64; } /* Literal Date */
.highlight .ld { color: #ff9e64; } /* Literal Number */
.highlight .m { color: #ff9e64; } /* Literal Number Float */
.highlight .mf { color: #ff9e64; } /* Literal Number Hex */
.highlight .mh { color: #ff9e64; } /* Literal Number Integer */
.highlight .mi { color: #ff9e64; } /* Literal Number Oct */
.highlight .mo { color: #ff9e64; } /* Literal Number Integer Long */
.highlight .il { color: #ff9e64; } /* Literal String */
.highlight .s { color: #9ece6a; } /* Literal String Backtick */
.highlight .sb { color: #ff9e64; } /* Literal String Char */
.highlight .sc { color: #9ece6a; } /* Literal String Doc */
.highlight .sd { color: #51597d; } /* Literal String Double */
.highlight .s2 { color: #9ece6a; } /* Literal String Escape */
.highlight .se { color: transparent; } /* Literal String Heredoc */
.highlight .sh { color: #9ece6a; } /* Literal String Interpol */
.highlight .si { color: #7dcfff; } /* Literal String Other */
.highlight .sx { color: #9ece6a; } /* Literal String Regex */
.highlight .sr { color: #ff9e64; } /* Literal String Single */
.highlight .s1 { color: #9ece6a; } /* Literal String Symbol */
.highlight .ss { color: #ff9e64; } /* Base Style */
.highlight .g { color: transparent; } /* Generic Deleted */
.highlight .gd { color: transparent; } /* Generic Emph */
.highlight .ge { color: transparent; } /* Generic Error */
.highlight .gr { color: transparent; } /* Generic Heading */
.highlight .gh { color: transparent; } /* Generic Inserted */
.highlight .gi { color: transparent; } /* Generic Output */
.highlight .go { color: transparent; } /* Generic Prompt */
.highlight .gp { color: transparent; } /* Generic Strong */
.highlight .gs { color: transparent; } /* Generic Subheading */
.highlight .gu { color: transparent; } /* Generic Traceback */
.highlight .gt { color: transparent; } /* Other */
.highlight .x { color: transparent; } /* Text Whitespace */
.highlight .w { color: transparent; }
